$white: #ffffff;
$primary1: #f7c621;
$black: #000000;
$btnColor: #0b0b0a;

@mixin main-font {
  font-family: "Poppins", sans-serif;
}

@mixin secondary-font {
  font-family: "Poppins", sans-serif;
}

@mixin hero_btn($col1, $col2, $pad1, $pad2) {
  display: inline-block;
  padding: $pad1 $pad2;
  border: 1px solid $col1;
  background-color: transparent;
  color: $col1;

  &:hover {
    background-color: $col1;
    color: $col2;
  }
}

@mixin upperBold {
  text-transform: uppercase;
  font-weight: bold;
}

html {
  overflow-x: hidden
}

body {
  @include main-font;
  color: #0c0c0c;
  background-color: #ffffff;
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
}

.layout_padding2-bottom {
  padding-bottom: 45px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

.heading_container {
  display: flex;
  margin-bottom: 45px;

  h2 {
    position: relative;
    margin: 0;
    padding: 10px 0;

    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: 100%;
      top: 50%;
      right: -45px;
      transform: translateY(-50%);
      z-index: 1;
      background-color: #dbc64c;
      background-repeat: no-repeat;
    }
  }
}

/*header section*/
.hero_area {
  min-height: 100vh;
  position: relative;
  background-image: url(../images/hero-bg.jpg);
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 65%;
    background-image: url(../images/hero-bg2.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.sub_page {
  .hero_area {
    min-height: auto;
    background-image: none;
    background-color: #e1f3fd;
  }

  .service_section {
    margin: 45px auto;
  }
}

.header_section {
  .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }

  .nav_container {
    margin: 0 auto;
  }
}

.custom_nav-container {
  .navbar-nav {
    .nav-item {
      .nav-link {
        margin: 10px 20px;
        padding: 0;
        color: #282828;
        text-align: center;
        position: relative;
        text-transform: uppercase;
      }
    }
  }
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.custom_nav-container .nav_search-btn {
  background-image: url(../images/search-icon.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center 5px;
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
}

.navbar-brand {
  display: flex;

  span {
    font-size: 24px;
    font-weight: 700;
    color: #0b0b0a;
    text-transform: uppercase;
  }
}

.custom_nav-container {
  z-index: 99999;
  padding-top: 20px;
  align-items: flex-end; //for this site only
}

.navbar-expand-lg .navbar-collapse {
  align-items: flex-end; //for this site only
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  background-image: url(../images/menu.png);
  background-size: 45px;
}

.quote_btn-container {}

/*end header section*/

/* slider section */
.slider_section {
  height: calc(100% - 80px);
  display: flex;
  align-items: center;
  z-index: 2;
  color: #3b3a3a;
  padding: 45px 0 75px 0;

  .row {
    align-items: center;
  }

  div#carouselExampleIndicators {
    width: 130%;
    margin-left: -20%;
    position: unset;
  }

  .box {
    padding-left: 15%;
    margin-bottom: 100px;

    .detail-box {
      padding-right: 10%;

      h4 {
        font-weight: bold;
        margin-bottom: 0;
        color: $white;
      }

      h1 {
        font-weight: bold;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-size: 3rem;
      }
    }

    .img-box {
      margin: 0 7%;

      img {
        width: 100%;
      }
    }

    .btn-box {
      margin-top: 15px;

      a {
        text-align: center;
        @include hero_btn($btnColor, $white, 8px, 30px);
      }
    }
  }

  .slider_form {
    width: 355px;
    padding: 25px;
    background-color: #282828;
    border-radius: 10px;
    margin: auto;

    h4 {
      font-weight: bold;
      text-align: center;
      color: $white;
      margin-bottom: 20px;
    }

    form {
      margin: 15px 0;

      input {
        width: 100%;
        height: 40px;
        border: none;
        outline: none;
        margin-bottom: 15px;
        padding-left: 15px;
      }

      .btm_input {
        display: flex;

        input {
          width: calc(95% - 116px);
          margin: 0;
          margin-right: 10px;
        }

        button {
          flex: 1;
          min-width: 116px;
          border: none;
          outline: none;
          background-color: $primary1;
          color: $white;
        }
      }
    }
  }

  .carousel-indicators {
    margin: 0;
    justify-content: flex-start;
    left: 10%;

    li {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: #282828;
      opacity: 1;

      &.active {
        background-color: #dbc64c;
      }
    }
  }
}

// end slider section

// about section

.about_section {
  .row {
    align-items: center;
  }

  .detail-box {
    position: relative;
    padding-right: 75px;

    a {
      @include hero_btn($btnColor, $white, 8px, 25px);
      margin-top: 25px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: 65%;
      top: 10%;
      right: 25px;
      z-index: 1;
      background-color: #dbc64c;
      background-repeat: no-repeat;
    }
  }

  .img-box {
    padding: 75px 45px;

    img {
      width: 100%;
      position: relative;
      z-index: 2;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 100%;
      background-color: #282828;
      z-index: 1;
      border-radius: 10px;
    }
  }
}

// end about section

// service section
.service_section {
  width: 95%;
  margin: auto;
  border: 1px solid #e5e5e5;

  .service_container {
    display: flex;
    text-align: center;
    justify-content: space-between;

    .box {
      .img-box {
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .detail-box {
        margin-top: 15px;

        h5 {
          font-weight: bold;
        }

        a {
          @include hero_btn($btnColor, $white, 7px, 20px);
        }
      }
    }
  }
}

// end service section

// news section

.news_section {
  .news_container {
    display: flex;
    justify-content: center;
    padding: 0 25px;

    .date-box {
      background-color: #252525;
      padding: 7px 25px;
      color: $white;
      margin-bottom: -15px;
      position: relative;
      z-index: 2;

      h6 {
        margin: 0;
      }
    }

    .box {
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img-box {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .detail-box {
        background-color: #f7c621;
        padding: 15px;

        h6 {
          font-weight: bold;
          font-size: 18px;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

// end news section

// client section

.client_section {
  .carousel-wrap {
    margin: 0 auto;
    padding: 0 5%;
    position: relative;
  }

  .owl-nav>div {
    margin-top: -26px;
    position: absolute;
    top: 50%;
    color: #cdcbcd;
  }

  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next {
    width: 50px;
    height: 50px;
    background-color: $black;
    background-size: 9px;
    background-position: center;
    border-radius: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    outline: none;

    &:hover {
      background-color: $primary1;
    }
  }

  .owl-carousel .owl-nav .owl-prev {
    background-image: url(../images/prev.png);
    left: -10%;

    &:hover {
      background-image: url(../images/prev-white.png);
    }
  }

  .owl-carousel .owl-nav .owl-next {
    right: -10%;
    background-image: url(../images/next.png);

    &:hover {
      background-image: url(../images/next-white.png);
    }
  }
}

// client section

.client_section {
  .box {
    padding: 35px 45px 15px 45px;
    margin: 15px;
    box-shadow: 0 0 25px 0 rgba($color: #000000, $alpha: 0.1);
    position: relative;

    .img-box {
      max-width: 125px;
      min-width: 125px;

      img {
        width: 100%;
      }
    }

    .detail-box {
      margin-top: 15px;

      img {
        width: 25px;
      }
    }

    >div {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 90px;
      background-color: $primary1;
      z-index: 1;
    }
  }

  .carousel-wrap {
    margin: 0 auto;
    padding: 0 5%;
    position: relative;
    margin-bottom: 45px;
  }

  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next {
    width: 50px;
    height: 50px;
    background-color: #252525;
    background-size: 15px;
    background-position: center;
    border-radius: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: initial;
    bottom: -75px;
    outline: none;

    &:hover {
      background-color: $primary1;
    }
  }

  .owl-carousel .owl-nav .owl-prev {
    background-image: url(../images/prev.png);
    left: -5%;
  }

  .owl-carousel .owl-nav .owl-next {
    left: calc(-5% + 55px);
    background-image: url(../images/next.png);
  }

  .owl-carousel .owl-dots.disabled,
  .owl-carousel .owl-nav.disabled {
    display: block;
  }
}

// end client section

// contact section

.contact_section {
  .row {
    align-items: center;
  }

  .contact_form {
    padding: 25px;
    background-color: #282828;
    margin: 0 45px;

    h4 {
      color: $white;
      margin-bottom: 20px;
    }

    form {
      margin: 15px 0;

      input {
        width: 100%;
        height: 40px;
        border: none;
        outline: none;
        margin-bottom: 15px;
        padding-left: 15px;

        &.message_input {
          height: 75px;
        }
      }

      button {
        padding: 8px 30px;
        border: none;
        outline: none;
        background-color: $primary1;
        color: $black;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  .img-box {
    img {
      width: 100%;
    }
  }
}

// end contact section

// app section

.app_section {
  background-color: $primary1;
  color: $white;

  .row {
    align-items: center;
  }

  .detail-box {
    h2 {
      color: #252525;
      text-transform: uppercase;
      font-weight: bold;
    }

    .text-box {
      margin-top: 25px;

      h5 {
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .btn-box {
      margin: 25px -5px 0 -5px;
      display: flex;

      a {
        margin: 5px;
      }
    }
  }

  .img-box {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }
}

// end app section

// why section

.why_section {
  .why_container {
    padding-left: 10%;
  }

  .box {
    display: flex;
    align-items: center;


    .img-box {
      min-width: 90px;
      min-height: 90px;
      background-color: #252525;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 45px;

      img {
        max-width: 30px;
        transition: all 1s;
      }

      .img-2 {
        display: none;
        opacity: 0;
      }
    }

    .detail-box {
      h5 {
        font-weight: bold;
      }

      p {
        margin: 0;
      }
    }

    &:nth-child(2) {
      margin: 35px 0;
    }

    &:hover {
      .img-box {
        background-color: $primary1;

        .img-1 {
          display: none;
          opacity: 0;
        }

        .img-2 {
          display: inline-flex;
          opacity: 1;
        }
      }
    }
  }
}

// end why section


/* info section */
.info_section {
  background-color: #161616;
  color: $white;

  .box {
    width: 100%;
    max-width: 675px;
    margin: 0 auto;
  }

  .info_form {
    margin: 0 auto;
    margin-bottom: 45px;

    h4 {
      text-align: center;
      margin-bottom: 35px;
    }

    form {
      display: flex;
      align-items: stretch;

      input {
        background-color: $white;
        border: none;
        width: calc(100% - 55px);
        outline: none;
        color: $black;
        height: 42.4px;
        padding-left: 15px;


        ::placeholder {
          color: #9a9999;
        }
      }

      button {
        border: none;
        outline: none;
        background-color: $primary1;
        width: 55px;
        font-size: 15px;
        text-transform: uppercase;
        background-image: url(../images/right-arrow.png);
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

  }

  .info_links {
    ul {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        list-style-type: none;
        flex: 1;
        text-align: center;
        display: flex;
        justify-content: center;

        a {
          color: $white;
          padding: 10px;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      margin: 15px 0;



    }
  }

  .info_social {
    display: flex;
    justify-content: center;
    margin: 45px auto 15px auto;

    img {
      width: 35px;
      margin: 0 7px;
    }
  }
}

/* end info section */



/* footer section*/

.footer_section {
  background-color: $primary1;
  display: flex;
  justify-content: center;
  position: relative;
}

.footer_section p {
  color: $black;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  width: 55%;
}

.footer_section {
  a {
    color: $black;
  }
}

// end footer section