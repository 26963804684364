html {
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  color: #0c0c0c;
  background-color: #ffffff;
}
.detail-box.slider_form2 {
  padding: 20px; /* Add padding for spacing */
  text-align: center; /* Center align the content */
  background-color: rgba(0, 0, 0, 0.7); /* Add a background color with some transparency */
  color: yellow;
  max-width: 100%; /* Ensure it doesn't go wider than the screen */
  margin: 0 auto; /* Center horizontally */
}

/* Media query for screens with a maximum width of 768px (typical mobile devices) */
@media (max-width: 768px) {
  .detail-box.slider_form2 {
    font-size: 14px; /* Adjust font size for readability on smaller screens */
  }
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
}

.layout_padding2-bottom {
  padding-bottom: 45px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

.heading_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 45px;
}

.heading_container h2 {
  position: relative;
  margin: 0;
  padding: 10px 0;
}
/* Your existing CSS styles */

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .news_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.heading_container h2::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  top: 50%;
  right: -45px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  background-color: #dbc64c;
  background-repeat: no-repeat;
}

/*header section*/
.hero_area {
  min-height: 100vh;
  position: relative;
  background-image: url(./images/hero-bg.jpg);
  background-size: cover;
}

.hero_area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 65%;
  background-image: url(./images/output-onlinepngtools.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

.sub_page .hero_area {
  min-height: auto;
  background-image: none;
  background-color: #e1f3fd;
}
.fare-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.estimated-fare {
  color: white;
  font-weight: bold;
  margin-left: 10px;
}

.white-bold-text {
  color: white;
  font-weight: bold;
}

.sub_page .service_section {
  margin: 45px auto;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.header_section .nav_container {
  margin: 0 auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  margin: 10px 20px;
  padding: 0;
  color: black;
  font-size: 20px;
  text-align: center;
  position: relative;
  text-transform: uppercase;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.custom_nav-container .nav_search-btn {
  background-image: url(./images/search-icon.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center 5px;
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
}
.yellow-text {
  color: yellow;
}
.navbar-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navbar-brand span {
  font-size: 24px;
  font-weight: 700;
  color: #0b0b0a;
  text-transform: uppercase;
}

.custom_nav-container {
  z-index: 99999;
  padding-top: 20px;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.navbar-expand-lg .navbar-collapse {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  background-image: url(./images/menu.png);
  background-size: 45px;
}

/*end header section*/
/* slider section */
.slider_section {
  height: calc(100% - 80px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
  color: #3b3a3a;
  padding: 45px 0 75px 0;
}

.slider_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.slider_section div#carouselExampleIndicators {
  width: 130%;
  margin-left: -20%;
  position: unset;
}

.slider_section .box {
  padding-left: 15%;
  margin-bottom: 100px;
}

.slider_section .box .detail-box {
  padding-right: 10%;
}

.slider_section .box .detail-box h4 {
  font-weight: bold;
  margin-bottom: 0;
  color: yellow;
}

.slider_section .box .detail-box h1 {
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 3rem;
}

.slider_section .box .img-box {
  margin: 0 7%;
}

.slider_section .box .img-box img {
  width: 100%;
}

.slider_section .box .btn-box {
  margin-top: 15px;
}

.slider_section .box .btn-box a {
  text-align: center;
  display: inline-block;
  padding: 8px 30px;
  border: 1px solid #0b0b0a;
  background-color: transparent;
  color: #0b0b0a;
}

.slider_section .box .btn-box a:hover {
  background-color: #0b0b0a;
  color: #ffffff;
}

.slider_section .slider_form {
  width: 400px;
  padding: 25px;
  background-color: purple;
  border-radius: 10px;
  margin: auto;
}
.slider_section .slider_form2 {
  width: 1200px;
  padding: 25px;
  background-color: purple;
  border-radius: 10px;
  margin: auto;
}
.slider_section .slider_form h4 {
  font-weight: bold;
  text-align: center;
  color: yellow;
  margin-bottom: 20px;
}

.slider_section .slider_form form {
  margin: 15px 0;
}

.slider_section .slider_form form input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  margin-bottom: 15px;
  padding-left: 15px;
}

.slider_section .slider_form form .btm_input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slider_section .slider_form form .btm_input input {
  width: calc(95% - 116px);
  margin: 0;
  margin-right: 10px;
}

.slider_section .slider_form form .btm_input button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 116px;
  border: none;
  outline: none;
  background-color: #f7c621;
  color: #ffffff;
}

.slider_section .carousel-indicators {
  margin: 0;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  left: 10%;
}

.slider_section .carousel-indicators li {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #282828;
  opacity: 1;
}

.slider_section .carousel-indicators li.active {
  background-color: #dbc64c;
}

.about_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about_section .detail-box {
  position: relative;
  padding-right: 75px;
}

.about_section .detail-box a {
  display: inline-block;
  padding: 8px 25px;
  border: 1px solid #0b0b0a;
  background-color: transparent;
  color: #0b0b0a;
  margin-top: 25px;
}

.about_section .detail-box a:hover {
  background-color: #0b0b0a;
  color: #ffffff;
}

.about_section .detail-box::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 65%;
  top: 10%;
  right: 25px;
  z-index: 1;
  background-color: #dbc64c;
  background-repeat: no-repeat;
}

.about_section .img-box {
  padding: 75px 45px;
}

.about_section .img-box img {
  width: 100%;
  position: relative;
  z-index: 2;
}

.about_section .img-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  background-color: purple;
  z-index: 1;
  border-radius: 10px;
}

.service_section {
  width: 95%;
  margin: auto;
  border: 1px solid #e5e5e5;
}

.service_section .service_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.service_section .service_container .box .img-box {
  height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.service_section .service_container .box .img-box img {
  max-width: 100%;
  max-height: 100%;
}

.service_section .service_container .box .detail-box {
  margin-top: 15px;
}

.service_section .service_container .box .detail-box h5 {
  font-weight: bold;
}

.service_section .service_container .box .detail-box a {
  display: inline-block;
  padding: 7px 20px;
  border: 1px solid #0b0b0a;
  background-color: transparent;
  color: #0b0b0a;
}

.service_section .service_container .box .detail-box a:hover {
  background-color: #0b0b0a;
  color: #ffffff;
}

.news_section .news_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 25px;
}

.news_section .news_container .date-box {
  background-color: #252525;
  padding: 7px 25px;
  color: yellow;
  margin-bottom: -15px;
  position: relative;
  z-index: 2;
}

.news_section .news_container .date-box h6 {
  margin: 0;
}

.news_section .news_container .box {
  margin: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.news_section .news_container .box .img-box {
  width: 100%;
}

.news_section .news_container .box .img-box img {
  width: 100%;
}

.news_section .news_container .box .detail-box {
  background-color: purple;
  padding: 15px;
}

.news_section .news_container .box .detail-box h6 {
  font-weight: bold;
  font-size: 18px;
}

.news_section .news_container .box .detail-box p {
  margin: 0;
}

.client_section .carousel-wrap {
  margin: 0 auto;
  padding: 0 5%;
  position: relative;
}

.client_section .owl-nav > div {
  margin-top: -26px;
  position: absolute;
  top: 50%;
  color: #cdcbcd;
}

.client_section .owl-carousel .owl-nav .owl-prev,
.client_section .owl-carousel .owl-nav .owl-next {
  width: 50px;
  height: 50px;
  background-color: #000000;
  background-size: 9px;
  background-position: center;
  border-radius: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  outline: none;
}

.client_section .owl-carousel .owl-nav .owl-prev:hover,
.client_section .owl-carousel .owl-nav .owl-next:hover {
  background-color: #f7c621;
}

.client_section .owl-carousel .owl-nav .owl-prev {
  background-image: url(./images/prev.png);
  left: -10%;
}

.client_section .owl-carousel .owl-nav .owl-prev:hover {
  background-image: url(./images/prev-white.png);
}

.client_section .owl-carousel .owl-nav .owl-next {
  right: -10%;
  background-image: url(./images/next.png);
}

.client_section .owl-carousel .owl-nav .owl-next:hover {
  background-image: url(./images/next-white.png);
}

.client_section .box {
  padding: 35px 45px 15px 45px;
  margin: 15px;
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.client_section .box .img-box {
  max-width: 125px;
  min-width: 125px;
}

.client_section .box .img-box img {
  width: 100%;
}

.client_section .box .detail-box {
  margin-top: 15px;
}

.client_section .box .detail-box img {
  width: 25px;
}

.client_section .box > div {
  position: relative;
  z-index: 2;
}

.client_section .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: purple;
  z-index: 1;
}

.client_section .carousel-wrap {
  margin: 0 auto;
  padding: 0 5%;
  position: relative;
  margin-bottom: 45px;
}

.client_section .owl-carousel .owl-nav .owl-prev,
.client_section .owl-carousel .owl-nav .owl-next {
  width: 50px;
  height: 50px;
  background-color: #252525;
  background-size: 15px;
  background-position: center;
  border-radius: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: initial;
  bottom: -75px;
  outline: none;
}

.client_section .owl-carousel .owl-nav .owl-prev:hover,
.client_section .owl-carousel .owl-nav .owl-next:hover {
  background-color: #f7c621;
}

.client_section .owl-carousel .owl-nav .owl-prev {
  background-image: url(./images/prev.png);
  left: -5%;
}

.client_section .owl-carousel .owl-nav .owl-next {
  left: calc(-5% + 55px);
  background-image: url(./images/next.png);
}

.client_section .owl-carousel .owl-dots.disabled,
.client_section .owl-carousel .owl-nav.disabled {
  display: block;
}

.contact_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contact_section .contact_form {
  padding: 25px;
  background-color: purple;
  margin: 0 45px;
}

.contact_section .contact_form h4 {
  color: yellow;
  margin-bottom: 20px;
}

.contact_section .contact_form form {
  margin: 15px 0;
}

.contact_section .contact_form form input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  margin-bottom: 15px;
  padding-left: 15px;
}

.contact_section .contact_form form input.message_input {
  height: 75px;
}

.contact_section .contact_form form button {
  padding: 8px 30px;
  border: none;
  outline: none;
  background-color: #f7c621;
  color: #000000;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.contact_section .img-box img {
  width: 100%;
}

.app_section {
  background-color: purple;
  color: #ffffff;
}

.app_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.app_section .detail-box h2 {
  color: #252525;
  text-transform: uppercase;
  font-weight: bold;
}

.app_section .detail-box .text-box {
  margin-top: 25px;
}

.app_section .detail-box .text-box h5 {
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: underline;
}

.app_section .detail-box .btn-box {
  margin: 25px -5px 0 -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.app_section .detail-box .btn-box a {
  margin: 5px;
}

.app_section .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.app_section .img-box img {
  max-width: 100%;
}

.why_section .why_container {
  padding-left: 10%;
}

.why_section .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.why_section .box .img-box {
  min-width: 90px;
  min-height: 90px;
  background-color: #252525;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 45px;
}

.why_section .box .img-box img {
  max-width: 30px;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.why_section .box .img-box .img-2 {
  display: none;
  opacity: 0;
}

.why_section .box .detail-box h5 {
  font-weight: bold;
}

.why_section .box .detail-box p {
  margin: 0;
}

.why_section .box:nth-child(2) {
  margin: 35px 0;
}

.why_section .box:hover .img-box {
  background-color: #f7c621;
}

.why_section .box:hover .img-box .img-1 {
  display: none;
  opacity: 0;
}

.why_section .box:hover .img-box .img-2 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  opacity: 1;
}

/* info section */
.info_section {
  background-color: #161616;
  color: #ffffff;
}

.info_section .box {
  width: 100%;
  max-width: 675px;
  margin: 0 auto;
}

.info_section .info_form {
  margin: 0 auto;
  margin-bottom: 45px;
}

.info_section .info_form h4 {
  text-align: center;
  margin-bottom: 35px;
}

.info_section .info_form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.info_section .info_form form input {
  background-color: #ffffff;
  border: none;
  width: calc(100% - 55px);
  outline: none;
  color: #000000;
  height: 42.4px;
  padding-left: 15px;
}

.info_section .info_form form input ::-webkit-input-placeholder {
  color: #9a9999;
}

.info_section .info_form form input :-ms-input-placeholder {
  color: #9a9999;
}

.info_section .info_form form input ::-ms-input-placeholder {
  color: #9a9999;
}

.info_section .info_form form input ::placeholder {
  color: #9a9999;
}

.info_section .info_form form button {
  border: none;
  outline: none;
  background-color: #f7c621;
  width: 55px;
  font-size: 15px;
  text-transform: uppercase;
  background-image: url(./images/right-arrow.png);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.info_section .info_links ul {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.info_section .info_links ul li {
  list-style-type: none;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.info_section .info_links ul li a {
  color: #ffffff;
  padding: 10px;
}

.info_section .info_links a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 15px 0;
}

.info_section .info_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 45px auto 15px auto;
}

.info_section .info_social img {
  width: 35px;
  margin: 0 7px;
}

/* end info section */
/* footer section*/
.footer_section {
  background-color: purple;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}

.footer_section p {
  color: #000000;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  width: 55%;
}

.footer_section a {
  color: #000000;
}
